import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { imageButton, imageStyle, textStyle } from "./image-button.module.scss"

const ImageButton = ({ image, title, text, to }) => {
  return (
    <div className={imageButton}>
      <Link to={to}>
        <img alt={title} className={imageStyle} src={image} />
        <span className={textStyle}>
          <>
            <h3>{title}</h3>
            {text}
          </>
        </span>
      </Link>
    </div>
  )
}

ImageButton.propTypes = {
  image: PropTypes.node,
  text: PropTypes.node,
  to: PropTypes.string,
}

export default ImageButton
