import React from "react"

import Seo from "../../components/seo"
import Layout from "../../components/layout"
import ImageButton from "../../components/imageButton"

import Vepa from "../../images/vepa_v2.png"
import secondHandImg from "../../images/btn_img_2.jpg"
import openingHoursImg from "../../images/btn_img_1.jpg"
import eventsImg from "../../images/btn_img_4.jpg"

import {
  content,
  sections,
  imageButtons,
  column,
  imageButton,
  vepa,
  textWrapper,
} from "./landing-page.module.scss"

const INSTAGRAM_LINK = "https://www.instagram.com/awesomerags_rawandrefined/"
const FACEBOOK_LINK = "https://www.facebook.com/awesomeragsrawandrefined/"

const LandingPage = () => {
  /* eslint-disable jsx-a11y/accessible-emoji */
  return (
    <Layout>
      <Seo title={"Hem"} />
      <div className={content}>
        <div className={sections}>
          <section className={imageButtons}>
            <div className={column}>
              <div className={imageButton}>
                <ImageButton
                  image={secondHandImg}
                  title={"Inlämning / Hämtning"}
                  text={"Klicka här för att se hur vår second hand fungerar!"}
                  to={"/secondHand"}
                />
              </div>
              <div className={imageButton}>
                <ImageButton
                  image={openingHoursImg}
                  title={"Öppettider"}
                  text={"Se våra öppettider och adress"}
                  to={"/openingHours"}
                />
              </div>
              <div className={imageButton}>
                <ImageButton
                  image={eventsImg}
                  title={"Event"}
                  text={
                    "Är du dina vänner/kollegor intresserade att ha event i vår butik?"
                  }
                  to={"/events"}
                />
              </div>
            </div>
          </section>
          <section>
            <img
              className={vepa}
              alt={"Raw and refined second hand store in Linköping"}
              src={Vepa}
            />
          </section>
        </div>
        <div className={textWrapper}>
          <h3>
            Följ oss på <a href={INSTAGRAM_LINK}>Instagram</a> &
            <a href={FACEBOOK_LINK}>Facebook</a> för uppdateringar!
          </h3>
        </div>
      </div>
    </Layout>
  )
  /* eslint-enable jsx-a11y/accessible-emoji */
}

export default LandingPage
